import React from 'react'
import { Container, Typography, Box } from '@mui/material';
import useStyles from './styles'

export default function LeftMenu() {
    const { classes, theme } = useStyles()
    return (
        <Container disableGutters className={classes.menu}>
            <Typography className={classes.title}>
                Ryquest
                <Box className={classes.betaBox}>
                    BETA
                </Box>
            </Typography>
            <Typography className={classes.text}>
                Login / Signup
            </Typography>
        </Container>
    )
}