import { HashRouter, Route, Routes } from 'react-router-dom'
import Display from '../components/Display/Display'

const Router = () => {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Display/>}/>
            </Routes>
        </HashRouter>
    )
}

export default Router