import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme: any) => ({
    menu: {
        width: '100%',
        background: '#DEEBF2',
        paddingTop: 30,
        display: 'flex',
        flexDirection: 'column',
        color: '#456490',
        alignItems: 'left',
        paddingLeft: 50
    },
    title: {
        fontSize: '24px',
        fontWeight: 500,
        fontFamily: 'Roboto Mono',
        lineHeight: 'auto'
    },
    text: {
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Roboto Mono',
        lineHeight: 'auto'
    }
}))

