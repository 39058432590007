import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme: any) => ({
    menu: {
        width: 400,
        background: '#456490',
        height: 'auto',
        paddingTop: 30,
        display: 'flex',
        flexDirection: 'column',
        color: '#DEEBF2',
        alignItems: 'end',
        paddingRight: 10
    },
    title: {
        fontSize: '24px',
        fontWeight: 500,
        fontFamily: 'Roboto Mono',
        lineHeight: 'auto',
        display: 'flex',
        flexDirection: 'row',
        gap: 8
    },
    betaBox: {
        fontSize: '12px',
        background: '#38A0FF',
        width: 34,
        height: 15,
        textAlign: 'center',
        marginTop: 11,
        paddingBottom: 3
    },
    text: {
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Roboto Mono',
        lineHeight: 'auto',
        paddingRight: 8,
        opacity: 0.1,
        cursor: 'not-allowed'
    }
}))

