import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme: any) => ({
    display: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'hidden',
        position: 'relative',
        float: 'left'
    }
}))

