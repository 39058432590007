import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './routes/Routes';
import reportWebVitals from './reportWebVitals';
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider
} from "@mui/material/styles";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const theme = createTheme();

root.render(
    <React.StrictMode>
  <ThemeProvider theme={theme}>
      <App />
  </ThemeProvider>

    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
