import React from 'react'
import { Container } from '@mui/material';
import useStyles from './styles'
import LeftMenu from '../LeftMenu/LeftMenu';
import RightContent from '../RightContent/RightContent';

export default function Display() {
    const { classes, theme } = useStyles()
    return (
        <Container disableGutters className={classes.display}>
            <LeftMenu />
            <RightContent />
        </Container>
    )
}